//noinspection CssUnknownTarget
@import url('https://fonts.googleapis.com/css?family=Roboto:100,400');

// Primary palette:
$essmed-green: #004393; //#99cc33;
$essmed-green-inactive: #38A9DB;
$essmed-grey: #9D9FA1; //#999999;
$essmed-success: #0078ba; //#17A244; //#A1CE85;
$essmed-yellow: #FFCB06;


@media print {
    body {
        margin: 0;
        padding: 0 !important;
        min-width: 992px;
    }
}

.iewarning {
    display: none;
}

html, body {
    font-family: Roboto, sans-serif !important;
    height: 100%;
    margin: 0; //min-height: 75rem; //Can be removed; just added for demo purposes
    padding-top: 3rem;
}

select.hide-default {
    &:focus option:first-of-type {
        display: none;
    }
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

//Custom success
.text-success { color: $essmed-green !important; }

.bg-success {
    background-color: $essmed-success !important;
}

.btn-success {
    color: #fff;
    background-color: $essmed-success !important;
    border-color: $essmed-success !important;
    &:hover {
        color: #fff;
        background-color: $essmed-green-inactive !important;
        border-color: $essmed-green-inactive !important;
    }
    &:focus, &.focus {
        //box-shadow: 0 0 0 2px rgba(92, 184, 92, 0.5);
        box-shadow: 0 0 0 2px rgba(0, 67, 147, 0.5);
    }
}
.btn-outline-success {
    @extend .btn-success;
    color: $essmed-success;
    background-color: transparent!important;
}

.btn-success:hover {
    color: #fff;
    background-color: $essmed-green !important; //#96C37B !important;
    border-color: $essmed-green !important; //#96C37B !important;
}

.btn-success:focus, .btn-success.focus {

    //box-shadow: 0 0 0 2px rgba(92, 184, 92, 0.5);
    box-shadow: 0 0 0 2px rgba(0, 67, 147, 0.5);
}

#new-look {
    margin-bottom: 1rem;
    .card-header {
    color: $essmed-green;
    font-size:1rem;
    }
}

.green-card {
    border-top:  solid 3px $essmed-green;
    margin-bottom: 1.5rem;
    .card-header {
        overflow: hidden;
        font-size: 1.25rem;
        font-weight: 500;
        line-height: inherit;
    }
    .card-header, .card-title {
        color: rgba(0, 0, 0, 0.7);
    }
    .card-text {
        margin-bottom: 2rem;
    }
    .card-text:last-child {
        margin-bottom: 0;
    }
    .premium {
        text-align: center;
    }
    #alert-over54 {
        display: none;
    }
    //#over-65 {
    //    color: darken($essmed-dodger-blue, 35%);
    //}
    #sections {
        display: none;
    }
    #save-quote {
        white-space: normal;
    }
    #benefits {
        //display: none;
        margin-bottom: 0;
        form-check-input {
            margin-right: 0.5rem;
        }
        p:first-child {
            display: none;
        }
        .list-group-item {
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
            .row {
                align-items: center;
            }
        }
        .benefit-level-header {
            background: #f7f7f7;
            padding-top: 0.25rem;
            padding-bottom: 0.25rem;
            font-size: 80%;
            font-weight: 400;
        }
        .benefit-section-header {
            background: $essmed-green; //#b8dc73;
            color: #ffffff
        }
    }
    .benefits-section {
        margin-bottom: 1rem;
        &:last-child {
            margin-bottom: 0rem;
        }
    }
    p.lead.small {
        //font-weight: 300;
        margin-bottom: 0.5rem;
    }

    .metadata {
        p {
            margin-bottom: 0;
        }
        .metadata-item {
            margin-bottom: 1rem;
        }
    }
    .member-role {
        //font-weight: 300;
        color: $essmed-green;
    }
    .member {
        margin-bottom: 1rem;
        &:last-child {
            margin-bottom: 0;
        }

        p {
            margin-bottom: 0;
        }
        .member-item {
            margin-bottom: 0;
        }
    }

    .disclaimer, #internal-use {
        border-top: 1px solid rgba(0,0,0,0.125);
        border-bottom: 1px solid rgba(0,0,0,0.125);
    }
}

.red-card {
    @extend .green-card;
    border-top:  solid 3px #dc3545;
}

//input[type=email], input[type=number], input[type=password], input[type=tel], input[type=date], input[type=time], input[type=text] {
x {
    transition: border-color .2s;

    appearance: none;
    padding: 6px 10px;
    height: 45px;
    border: 1px solid #e4ebef;
    border-radius: 0.15rem;
    background-color: #fff;
    vertical-align: middle;
    line-height: normal;
    max-width: 100%;
    font-size: 14px;
    color: #7c7c80;
    width: 100%;
}

// Make Safari not style the thing with a gradient
//select {
//    -webkit-appearance: None;
//}

.navbar {
    //font-size: 13px;
    font-size: 1rem;
    margin-bottom: 0;
    background-color: #ffffff;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    transition: all .4s ease-in-out;
}

.navbar img {
    max-width: 200px !important;
    max-height: 60px !important;
    margin-right: 1rem;
}

.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
    color: rgba(0, 0, 0, 0.7);
}


.price-card {
    .premium {
        color: rgba(0, 0, 0, 0.7);
        padding: 1rem;
        margin: 0;
    }

    #save-quote {
        padding: 0 1rem 1rem 1rem;
    }
}

.application-card {
    @extend .green-card;
    .card-body {
        border-top: 1px solid rgba(0,0,0,0.125);
    }
    .card-text {
        margin-bottom: 0;
    }
    .card-title {
        font-size: 1.25rem;
    }
}


.ribbon {
    margin-bottom: 1rem;
}

footer {
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin-top: 2rem;
    p {
        margin-bottom: .25rem;
    }
}

#site-footer {
    border-top: 1px solid rgba(210, 216, 220, .60);
}


.hide {
    display: none;
}

.level-selector {

    height: 30px;
    width: 30px;
    border-radius: 50%;
    cursor: pointer;

}


@media (min-width: 992px) {
    .nav-item {
        margin-top: auto;
        margin-bottom: auto;
    }

}


.benefit.modal {

    // let model size itself
    /// https://stackoverflow.com/a/35056763
    .modal-dialog {
        position: relative;
        display: table; /* This is important */
        overflow-y: auto;
        overflow-x: auto;
        width: auto;
        min-width: 300px;
    }

    .level-label {
        white-space: nowrap;
    }

    @for $i from 1 through 5 {
        .level-label.level-#{$i} {
            //color: lighten($essmed-green, 5% * $i);
            color: $essmed-green;
        }
        button.level-#{$i} {
//            border-color: lighten($essmed-green, 5% * $i);
//            color: lighten($essmed-green, 5% * $i);
        }
    }

    .preamble {
        border-collapse: collapse;
        border-bottom: 1px solid #eceeef;
        padding: 1em;
        //font-weight: 300;
    }

    .description {
        margin-top: 2em;
        margin-bottom: 2em;
    }

    .card {
        margin-bottom: 15px;
        &:last-child {
            margin-bottom: 0;
        }
    }

    .card-footer {
        background-color: #fff;
        border-top: 0;
    }

    .card-body {
        padding: 0.5rem;
        font-size: 0.8rem;
        li:last-child, ul {
            margin-bottom: 0;
        }

        button {
            cursor: pointer;
        }
    }


    .post-amble {
        display: flex;
        align-items: center;
        flex-direction: row;
        border-collapse: collapse;
        border-top: 1px solid #eceeef;
        padding: 0.75em;
        .waiting-period {
            margin-left: 1em;
        }
    }

}

.no-margins {
    margin: 0;
}

.no-radiuses {
    border-radius: 0;
}

.no-side-borders {
    border-left: 0;
    border-right: 0;
}

.opacity-0 {
    opacity: 0;
}


.parsley-error {
    background-color: #F2DEDE;
}
.parsley-success {
    background-color: #DFF0D8;
}

.parsley-errors-list {

    padding: 0;
    list-style-type: none;
    color: red;

}

#group-500 {
    h1 {
       padding-bottom: 0.75rem;
        margin-bottom: 0.75rem;
    }
}

.dataTables_wrapper {
    font-size: 0.8rem;
    thead {
        background-color: $essmed-success;
    }
}

.dataTables_filter {
    display: None;
}

.parsley-errors-list {
    font-size: 0.8rem;
}