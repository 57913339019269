@import url("https://fonts.googleapis.com/css?family=Roboto:100,400");
@media print {
  body {
    margin: 0;
    padding: 0 !important;
    min-width: 992px;
  }
}

.iewarning {
  display: none;
}

html, body {
  font-family: Roboto, sans-serif !important;
  height: 100%;
  margin: 0;
  padding-top: 3rem;
}

select.hide-default:focus option:first-of-type {
  display: none;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

.text-success {
  color: #004393 !important;
}

.bg-success {
  background-color: #0078ba !important;
}

.btn-success, .btn-outline-success {
  color: #fff;
  background-color: #0078ba !important;
  border-color: #0078ba !important;
}

.btn-success:hover, .btn-outline-success:hover {
  color: #fff;
  background-color: #38A9DB !important;
  border-color: #38A9DB !important;
}

.btn-success:focus, .btn-outline-success:focus, .btn-success.focus, .focus.btn-outline-success {
  box-shadow: 0 0 0 2px rgba(0, 67, 147, 0.5);
}

.btn-outline-success {
  color: #0078ba;
  background-color: transparent !important;
}

.btn-success:hover, .btn-outline-success:hover {
  color: #fff;
  background-color: #004393 !important;
  border-color: #004393 !important;
}

.btn-success:focus, .btn-outline-success:focus, .btn-success.focus, .focus.btn-outline-success {
  box-shadow: 0 0 0 2px rgba(0, 67, 147, 0.5);
}

#new-look {
  margin-bottom: 1rem;
}

#new-look .card-header {
  color: #004393;
  font-size: 1rem;
}

.green-card, .red-card, .application-card {
  border-top: solid 3px #004393;
  margin-bottom: 1.5rem;
}

.green-card .card-header, .red-card .card-header, .application-card .card-header {
  overflow: hidden;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: inherit;
}

.green-card .card-header, .red-card .card-header, .application-card .card-header, .green-card .card-title, .red-card .card-title, .application-card .card-title {
  color: rgba(0, 0, 0, 0.7);
}

.green-card .card-text, .red-card .card-text, .application-card .card-text {
  margin-bottom: 2rem;
}

.green-card .card-text:last-child, .red-card .card-text:last-child, .application-card .card-text:last-child {
  margin-bottom: 0;
}

.green-card .premium, .red-card .premium, .application-card .premium {
  text-align: center;
}

.green-card #alert-over54, .red-card #alert-over54, .application-card #alert-over54 {
  display: none;
}

.green-card #sections, .red-card #sections, .application-card #sections {
  display: none;
}

.green-card #save-quote, .red-card #save-quote, .application-card #save-quote {
  white-space: normal;
}

.green-card #benefits, .red-card #benefits, .application-card #benefits {
  margin-bottom: 0;
}

.green-card #benefits form-check-input, .red-card #benefits form-check-input, .application-card #benefits form-check-input {
  margin-right: 0.5rem;
}

.green-card #benefits p:first-child, .red-card #benefits p:first-child, .application-card #benefits p:first-child {
  display: none;
}

.green-card #benefits .list-group-item, .red-card #benefits .list-group-item, .application-card #benefits .list-group-item {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.green-card #benefits .list-group-item .row, .red-card #benefits .list-group-item .row, .application-card #benefits .list-group-item .row {
  align-items: center;
}

.green-card #benefits .benefit-level-header, .red-card #benefits .benefit-level-header, .application-card #benefits .benefit-level-header {
  background: #f7f7f7;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-size: 80%;
  font-weight: 400;
}

.green-card #benefits .benefit-section-header, .red-card #benefits .benefit-section-header, .application-card #benefits .benefit-section-header {
  background: #004393;
  color: #ffffff;
}

.green-card .benefits-section, .red-card .benefits-section, .application-card .benefits-section {
  margin-bottom: 1rem;
}

.green-card .benefits-section:last-child, .red-card .benefits-section:last-child, .application-card .benefits-section:last-child {
  margin-bottom: 0rem;
}

.green-card p.lead.small, .red-card p.lead.small, .application-card p.lead.small {
  margin-bottom: 0.5rem;
}

.green-card .metadata p, .red-card .metadata p, .application-card .metadata p {
  margin-bottom: 0;
}

.green-card .metadata .metadata-item, .red-card .metadata .metadata-item, .application-card .metadata .metadata-item {
  margin-bottom: 1rem;
}

.green-card .member-role, .red-card .member-role, .application-card .member-role {
  color: #004393;
}

.green-card .member, .red-card .member, .application-card .member {
  margin-bottom: 1rem;
}

.green-card .member:last-child, .red-card .member:last-child, .application-card .member:last-child {
  margin-bottom: 0;
}

.green-card .member p, .red-card .member p, .application-card .member p {
  margin-bottom: 0;
}

.green-card .member .member-item, .red-card .member .member-item, .application-card .member .member-item {
  margin-bottom: 0;
}

.green-card .disclaimer, .red-card .disclaimer, .application-card .disclaimer, .green-card #internal-use, .red-card #internal-use, .application-card #internal-use {
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.red-card {
  border-top: solid 3px #dc3545;
}

x {
  transition: border-color .2s;
  appearance: none;
  padding: 6px 10px;
  height: 45px;
  border: 1px solid #e4ebef;
  border-radius: 0.15rem;
  background-color: #fff;
  vertical-align: middle;
  line-height: normal;
  max-width: 100%;
  font-size: 14px;
  color: #7c7c80;
  width: 100%;
}

.navbar {
  font-size: 1rem;
  margin-bottom: 0;
  background-color: #ffffff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  transition: all .4s ease-in-out;
}

.navbar img {
  max-width: 200px !important;
  max-height: 60px !important;
  margin-right: 1rem;
}

.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
  color: rgba(0, 0, 0, 0.7);
}

.price-card .premium {
  color: rgba(0, 0, 0, 0.7);
  padding: 1rem;
  margin: 0;
}

.price-card #save-quote {
  padding: 0 1rem 1rem 1rem;
}

.application-card .card-body {
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.application-card .card-text {
  margin-bottom: 0;
}

.application-card .card-title {
  font-size: 1.25rem;
}

.ribbon {
  margin-bottom: 1rem;
}

footer {
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-top: 2rem;
}

footer p {
  margin-bottom: .25rem;
}

#site-footer {
  border-top: 1px solid rgba(210, 216, 220, 0.6);
}

.hide {
  display: none;
}

.level-selector {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  cursor: pointer;
}

@media (min-width: 992px) {
  .nav-item {
    margin-top: auto;
    margin-bottom: auto;
  }
}

.benefit.modal .modal-dialog {
  position: relative;
  display: table;
  /* This is important */
  overflow-y: auto;
  overflow-x: auto;
  width: auto;
  min-width: 300px;
}

.benefit.modal .level-label {
  white-space: nowrap;
}

.benefit.modal .level-label.level-1 {
  color: #004393;
}

.benefit.modal .level-label.level-2 {
  color: #004393;
}

.benefit.modal .level-label.level-3 {
  color: #004393;
}

.benefit.modal .level-label.level-4 {
  color: #004393;
}

.benefit.modal .level-label.level-5 {
  color: #004393;
}

.benefit.modal .preamble {
  border-collapse: collapse;
  border-bottom: 1px solid #eceeef;
  padding: 1em;
}

.benefit.modal .description {
  margin-top: 2em;
  margin-bottom: 2em;
}

.benefit.modal .card {
  margin-bottom: 15px;
}

.benefit.modal .card:last-child {
  margin-bottom: 0;
}

.benefit.modal .card-footer {
  background-color: #fff;
  border-top: 0;
}

.benefit.modal .card-body {
  padding: 0.5rem;
  font-size: 0.8rem;
}

.benefit.modal .card-body li:last-child, .benefit.modal .card-body ul {
  margin-bottom: 0;
}

.benefit.modal .card-body button {
  cursor: pointer;
}

.benefit.modal .post-amble {
  display: flex;
  align-items: center;
  flex-direction: row;
  border-collapse: collapse;
  border-top: 1px solid #eceeef;
  padding: 0.75em;
}

.benefit.modal .post-amble .waiting-period {
  margin-left: 1em;
}

.no-margins {
  margin: 0;
}

.no-radiuses {
  border-radius: 0;
}

.no-side-borders {
  border-left: 0;
  border-right: 0;
}

.opacity-0 {
  opacity: 0;
}

.parsley-error {
  background-color: #F2DEDE;
}

.parsley-success {
  background-color: #DFF0D8;
}

.parsley-errors-list {
  padding: 0;
  list-style-type: none;
  color: red;
}

#group-500 h1 {
  padding-bottom: 0.75rem;
  margin-bottom: 0.75rem;
}

.dataTables_wrapper {
  font-size: 0.8rem;
}

.dataTables_wrapper thead {
  background-color: #0078ba;
}

.dataTables_filter {
  display: None;
}

.parsley-errors-list {
  font-size: 0.8rem;
}
